.swiper-container {
    list-style: none;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding: 0px;
    position: relative;
    z-index: 1;
}
.swiper-wrapper {
    box-sizing: content-box;
    display: flex;
    transform: translateZ(0px);
    z-index: 1;
}

.swiper-slide, .swiper-wrapper {
    height: 100%;
    position: relative;
    transition-property: transform, -webkit-transform;
    width: 100%;
}

.swiper-slide {
    flex-shrink: 0;
}

.swiper-container-wp8-vertical, .swiper-container-wp8-vertical > .swiper-wrapper {
    touch-action: pan-x;
}

.swiper-button-next, .swiper-button-prev {
    background-position: 50% center;
    background-repeat: no-repeat;
    background-size: 27px 44px;
    cursor: pointer;
    height: 44px;
    margin-top: -22px;
    position: absolute;
    top: 50%;
    width: 27px;
    z-index: 10;
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
    cursor: auto;
    opacity: 0.35;
    pointer-events: none;
}

.swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22 22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23007aff'/%3E%3C/svg%3E");
    left: 10px;
    right: auto;
}

.swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22 5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23007aff'/%3E%3C/svg%3E");
    left: auto;
    right: 10px;
}

.swiper-button-prev.swiper-button-white {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22 22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23fff'/%3E%3C/svg%3E");
}

.swiper-button-next.swiper-button-white {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22 5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23fff'/%3E%3C/svg%3E");
}

.swiper-button-prev.swiper-button-black {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22 22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z'/%3E%3C/svg%3E");
}

.swiper-button-next.swiper-button-black {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22 5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z'/%3E%3C/svg%3E");
}

.swiper-pagination {
    position: absolute;
    text-align: center;
    transform: translateZ(0px);
    transition: opacity 0.3s ease 0s;
    z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
    opacity: 0;
}

.swiper-pagination-custom {
    bottom: 10px;
    left: 0px;
    width: 100%;
}

@-webkit-keyframes swiper-preloader-spin {
    100% {
        transform: rotate(1turn);
    }
}

@keyframes swiper-preloader-spin {
    100% {
        transform: rotate(1turn);
    }
}

.swiper-container-fade .swiper-slide {
    pointer-events: none;
    transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none;
}

.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
}
