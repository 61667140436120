/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}
/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: 'Montserrat';
  cursor: default;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  height: auto;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
 animation-duration: 0.01ms !important;
 animation-iteration-count: 1 !important;
 transition-duration: 0.01ms !important;
 scroll-behavior: auto !important;
  }
}
/* buttons */
.overlay-color {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    display: block;
    transition: all 0.5s ease-in-out 0s;
    background: var(--black);
    opacity: 0.5;
}
.wp-block-buttons.is-content-justification-right>.wp-block-button{
    margin-left: 15px;
}
.wp-block-button__link, input[type=button], .btn-primary{
    margin-bottom: 0;
    margin-right: 1rem;
    font-size: 1.15rem;
    background: rgba(0, 0, 0, 0);
    color: white;
    border-color: var(--accent);
    min-width: 200px;
    margin-bottom: 1rem;
    text-decoration: none!important;
    border-radius: 0;
}
.wp-block-button__link, .wp-block-advgb-button a, input[type=button], .btn-primary {
    position: relative!important;
    overflow: hidden!important;
    display: inline-block!important;
    text-decoration: none!important;
    text-align: center!important;
    text-transform: capitalize!important;
    outline: none!important;
    border: 2px solid var(--accent);
    padding: .625rem 2rem!important;
    font-size: 1rem!important;
    font-weight: 500!important;
    box-shadow: 0px 7px 10px -5px rgba(0, 0, 0, 0.25)!important;
    transition: all 0.5s ease-in-out 0s;
    cursor: pointer;
    z-index: 0;
  }
.wp-block-button__link:focus, .wp-block-advgb-button a:focus, input[type=button]:focus, .btn-primary:focus {
    outline: none!important;
}
.is-style-fill .wp-block-button__link:hover, .wp-block-advgb-button a:hover, input[type=button]:hover{
    color: white;
    background: rgba(0, 0, 0, 0)!important;
    border-color: white;
    text-decoration: none;
}  
.wp-block-button__link:hover::before, .wp-block-button__link:hover::after, 
input[type=button]:hover::before, input[type=button]:hover::after, .btn-primary:hover::before, .btn-primary:hover::after {
    left: 150%;
    transform: rotate(-15deg) translateX(100%);
}
.wp-block-button__link::before, .wp-block-button__link::after, 
input[type=button]::before, input[type=button]::after,
.btn-primary::before, .btn-primary::after {
    content: '';
    position: absolute;
    width: 30px;
    left: 0;
    top: -50%;
    height: 200%;
    background-color: var(--light-gray-text);
    transform: rotate(-15deg) translateX(-150%);
    z-index: -1;
    transition: all 0.5s ease-in-out 0s;
}
.wp-block-button__link::after, input[type=button]::after, .btn-primary::after {
    transition-delay: .075s;
}
.wp-block-advgb-button a{
    border: 2px solid!important;
}
.wp-block-advgb-button a:hover::before, .wp-block-advgb-button a:hover::after {
    left: 100%;
    transform: rotate(-15deg) translateX(150%);
}
.wp-block-advgb-button a::before, .wp-block-advgb-button a::after {
    content: '';
    position: absolute;
    width: 30px;
    left: 0;
    top: -50%;
    height: 200%;
    background-color: var(--light-gray-text);
    transform: rotate(-15deg) translateX(-150%);
    z-index: -1;
    transition: all 0.5s ease-in-out 0s;
}
.wp-block-advgb-button a::after {
    transition-delay: .065s;
}
.is-style-outline a.wp-block-button__link{
    background-color: transparent!important;
}
.is-style-outline a.wp-block-button__link:hover, .btn-primary:hover{
    background-color: var(--accent)!important;
    border-color: var(--accent)!important;
    color: var(--white)!important;
}
.btn-primary{
    background-color: var(--accent)!important;
    color: white!important;
}

/*кнопки*/
div#main .entry-header{
    display: none;
}
header#masthead .navbar{
    position: fixed;
    z-index: 1;
}
h1, h2, h3, h4, h5{
    font-family: "Montserrat Alternates", "sans-serif";
}
h3{
    font-size: 1.35rem;
}
h5{
    font-weight: 600;
}
.hero-swiper-slider .swiper-slide{
    height: auto!important;
}
.hero-swiper-slider .wp-block-image img{
    max-width: 100%;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.page-hero h6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
h6{
    text-transform: none;
    font-style: inherit;
    text-align: inherit;
    color: inherit;
    font-size: 1.15rem;
    margin-bottom: 1.5rem;
}
.hero-text-area{
    color: white;
}
.page-hero .hero-text-area .hero-title, .hero-swiper-slider .slide-title{
    margin-bottom: 0;
    font-weight: 500;
}
.page-hero .hero-text-area .hero-title, .hero-swiper-slider .slide-title + p{
    margin-top: 1.5rem;
}
.hero-text-area p{
    color: var(--light-gray-text);
    font-size: 1.15rem;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 1.7;
}
.hero-text-area .wp-block-buttons{
    margin-top: 2rem;
}
.wp-block-getwid-icon-box{
    margin-bottom: .8rem;
}
.wp-block-image, .alignleft{
    margin-bottom: 0;
    margin: 0!important;
}
p{
    margin-bottom: 3rem;
}
.img-area.framed figure{
    position: relative;
    z-index: 0;
}
.img-area.framed figure::before, .about .img-area.framed figure::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    border: 2px solid white;
    z-index: 5;
}
.img-area.framed figure::before {
    border-right: none;
    border-bottom: none;
    top: 30px;
    left: 30px;
}
.img-area.framed figure::after {
    border-left: none;
    border-top: none;
    bottom: 30px;
    right: 30px;
}
/* header style */
.page-header {
    padding-top: 0!important;
}
h1.entry-title{
    padding-top: 50px;
}
.page-header .navbar-brand {
    max-width: 100px;
}
a {
    color: var(--accent);
    text-decoration: none;
    background-color: transparent;
}
a:hover, a:visited, a:focus {
    color: var(--accent);
}
.navbar-light a.nav-link{
    color: white!important;
}
.header-stand-out a.nav-link{
    color: var(--shark)!important;
}
.menu-item-has-children > a::after{
    border-top: 0;
}
.menu-item-has-children > a::before{
    float: right;
    border-left: .3em solid transparent;
    content: "\f078";
    font-family: "Font Awesome 5 Free";
}
.menu-item-has-children .menu-item-has-children > a::before{
    content: "\f054";
}
li.menu-item-has-children ul.dropdown-menu{
    list-style: none;
    position: absolute;
    top: 100%;
    min-width: 18rem;
    left: 0;
    display: block;
    margin: 0;
    padding: 1rem 0;
    transform: translateY(4rem);
    visibility: hidden;
    opacity: 0;
    z-index: 1000;
    box-shadow: 0px 0px 30px 0px rgb(37 37 37 / 25%);
    background-color: white;
    border: none;
    border-radius: 0;
    transition: all .5s ease-in-out 0s;
}
li.menu-item-has-children:hover ul.dropdown-menu{
    display: block;
    opacity: 1;
    visibility: visible;
    transform: translateY(1rem);    
    transition: all .5s ease-in-out 0s;
}
li.menu-item-has-children li.menu-item-has-children .dropdown-menu {
    left: 100%;
    right: auto;
    visibility: hidden;
    display: none;
    opacity: 0;
    transform: translateY(4rem);    
    transition: all .5s ease-in-out 0s;
}
li.menu-item-has-children li.menu-item-has-children:hover .dropdown-menu {
    top: -1rem;
    display: block;
    opacity: 1;
    visibility: visible;
    transform: translateY(1rem);  
    transition: all .5s ease-in-out 0s;  
}
.navbar-light a.dropdown-item{
    color: var(--shark);
    padding: 1rem 1rem 1rem 1.5rem !important;
    transition: all 0.25s ease-in-out 0s;
    position: relative;
    font-size: .85rem;
    font-weight: 700;
    letter-spacing: .75px;
    opacity: .75;
}
.navbar-light a.dropdown-item:hover {
    color: white;
    background-color: var(--shark);
    opacity: 1;
}
.navbar-nav a[href^="tel:"] {
	font-size: 1.3rem;
    font-weight: 700;
    padding: 2px!important;
}
.navbar-nav a[href^="tel:"]:hover::after {
	text-decoration: none!important;
    visibility: hidden;
}
.page-header .navbar-nav{
    align-items: center;
}
/* price block */
.service-box h3{
    text-transform: capitalize;
    font-size: 1.35rem;
    font-weight:500;
    font-family: "Montserrat Alternates", "sans-serif";
    color: white;
    margin-bottom: 1rem;
    transition: all 0.5s ease-in-out 0s;
    padding: 0 1.7rem;
}
.service-box p{
    color: white;
    margin-bottom: 1rem;
}
.service-box h4{
    margin-bottom: 1.5rem;
    color: var(--accent);
}
.service-box i{
    margin-bottom: 1.5rem;
}
.service-box a.wp-block-button__link{
    margin: 0;
}
.service-box{
    color: white;
    text-align: left;
}
.service-box img{
    max-height: 100%;
    width: 100%;
    object-fit: cover;
    height: -webkit-fill-available;
}
.services-bg-img .service-box .bg-img{
    z-index: -2;
}
.services-bg-img .service-box .overlay-color{
    opacity: 0.9;
}
.service-box:hover .overlay-color{
    opacity: 0.8;
}
.wp-block-nk-awb {
    padding: 5rem 0 0;
    margin-top: -5rem;
}
.nk-awb .nk-awb-wrap{
    height: 50%;
}
.services-bg-img h2{
    color: white;
}
/* price block end */

/* benefits block */
.benefits .benefits-boxes-area::before, .benefits .benefits-boxes-area::after {
    background-color: var(--accent-light);
}
.benefits .benefits-boxes-area {
    border: 2px solid var(--accent-light);
}
.uagb-section__wrap{
    max-width: inherit!important;
    padding-top: inherit!important;
    padding-bottom: inherit!important;
    padding-left: inherit!important;
    padding-right: inherit!important;
    margin-right: inherit!important;
    margin-left: inherit!important;
}
.benefits p {
    margin-bottom: 0;
    color: var(--gray);
    font-size: 1rem;
    font-weight: 600;
}
.benefits h3 {
    margin-bottom: 0;
    margin-top: 1rem;
    margin-bottom: .5rem;
}
.benefits-image-area > div, .benefits-image-area > div > figure{
    height: 100%;
}
.benefits-image-area img{
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 100%;
    transform-origin: center;
    object-fit: cover;
}
.benefits .benefits-boxes-area .benefit-box{
    padding: 4rem 2rem;
}
/* faq */
.advgb-accordion-header, .advgb-accordion-header.ui-accordion-header.ui-state-default{
    padding: .75rem 40px .75rem 1.25rem!important;
}
.faq  p{
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.75;
    color: var(--gray);
    margin-bottom: 1rem;
}
/* разделитель */
.wp-block-separator {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 3rem;
    display: block;
    width: 8px;
    height: 8px;
    position: relative;
    background: accent;
}
.wp-block-separator:before {
    left: 0;
    right: auto;
    transform: translate(-150%, -50%);
}
.wp-block-separator:after {
    right: 0;
    left: auto;
    transform: translate(150%, -50%);
}
.wp-block-separator:before, .wp-block-separator:before, .wp-block-separator:after, .wp-block-separator:after {
    content: '';
    position: absolute;
    background: inherit;
    width: 25px;
    height: 4px;
    border-radius: 1rem;
    top: 50%;
}
/* отзывы */
.wp-block-getwid-icon i{
    top: 3px;
}
.testimonial-content p{
    margin-bottom: 0;
}
.testimonials .overlay-photo-image-bg{
    z-index: 0;
}
.testimonials .wp-block-getwid-section__background{
    background-position: right;
    background-size: 70%;
}
/*footer*/
#footer-widget{
    background-color: var(--shark)!important;
    color:  var(--light-gray);
    padding-bottom: 4rem;
    padding-top: 4rem;
}
i.wpmi-icon{
    background-color: var(--accent);
    color: white;
    padding: 10px;
    font-size: 14px!important;
    width: 34px;
    text-align: center;
}
#footer-widget li.menu-item a{
    padding: .35rem 0!important;
    color:  var(--light-gray);
    font-size: .85rem;
    font-weight: 500;
    transition: all 0.25s ease-in-out 0s;
}
#footer-widget li.menu-item a:hover{
    transform: translateX(0.25rem);
    transition: all 0.25s ease-in-out 0s;
}
.entry-footer {
    display: none;
}
#footer-widget  h3.widget-title{
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 1rem;
    color: var(--accent);
    font-family: inherit!important;
}
#footer-widget  p{
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.75;
    padding-right: 1rem;
    margin-bottom: 1.25rem;
}
#footer-widget .textwidget a{
    font-weight: 600;
    color:  var(--light-gray);
}
/* форма */
div.forminator-ui{
    display: none;
}
form.forminator-ui{
    display: block!important;
}
div.sgpb-content{
    padding: 0 2rem!important;
}
.forminator-label{
    font-size: 1rem!important;
    font-weight: 600!important;
}
.forminator-button{
    border: 2px solid var(--accent)!important;
}
.forminator-row{
    margin-left: 0!important;
    margin-right: 0!important;
}
.forminator-col{
    padding: 0!important;
}
/*другеи стили*/

#contacts p{
    margin-bottom: 0;
}
.sgpb-popup-dialog-main-div-wrapper > div > img{
    top: 15px!important;
    right: 15px!important;
}

.callButton a{
    border-radius: 0;
    border-color: white;
    margin: 0;
    top: 3px;
}
.callButton a:hover{
    border-color: var(--accent);
    background-color: var(--accent);
}
.header-stand-out .callButton a{
    border-radius: 0;
    border-color: white;
    color: var(--shark)!important;
    border-color: var(--shark);
    font-weight: 600;
}
.header-stand-out .callButton a:hover{
    border-color: var(--accent);
    background-color: var(--accent);
    color: white!important;
}
.dropdown-toggle::after{
    margin-left: 0;
}
.page-header .dropdown-menu .nav-item {
    margin-right: 0;
}
.hero-swiper-slider .swiper-button-next{
    margin-top: -5rem!important;
}
.hero-swiper-slider .swiper-button-prev:hover, .hero-swiper-slider .swiper-button-next:hover {
    background-color: var(--accent-light)!important;
}
@media (max-width: 1024px) {
    .quiz.mega-section{
        padding: 0;
    }
}
@media (max-width: 991px) {
    #menu-menu-header li a{
        padding: 1.25rem 1rem!important;
    }
    #menu-menu-header {
        align-items: flex-start;
    }
    .page-header .callButton {
        padding: 1rem;
    }
    .light-header.header-stand-out .nav-link:hover {
        color: white!important;
        background-color: var(--shark);
    }
    li.menu-item-has-children ul.dropdown-menu{
        display: none;
        position: initial;
        padding-bottom: 1rem;
        padding-left: 1rem;
        box-shadow: none!important;
        transition: all .5s ease-in-out 0s;
    }
    li.menu-item-has-children:hover ul.dropdown-menu{
        display: none;
    }
    li.menu-item-has-children:hover ul.dropdown-menu{
        transform: translateY(0rem);

    }
    .menu-item-has-children .menu-item-has-children > a::before {
        content: " ";
    }
    .hero-swiper-slider .swiper-button-next {
        margin-top: 0!important;
        position: absolute!important;
    }
    .hero-swiper-slider .swiper-button-next {
        right: 15px;
    }
    .hero-swiper-slider .swiper-button-prev, .hero-swiper-slider .swiper-button-next {
        bottom: 0.25rem;
    }
    .wp-block-buttons>.wp-block-button{
        margin-bottom: 0;
        margin-right: 0;
    }
    .hero-swiper-slider .swiper-slide {
        height: auto !important;
    }
    .hero-swiper-slider .swiper-slide-active .hero-text-area {
        opacity: 1;
        visibility: visible;
        margin-bottom: 4rem;
    }
    h6{
        margin-top: 2rem;
        font-size: 1rem;
    }
    .about .info-items-grid {
        padding: 2rem 0;
    }
    .services .service-box, .services-boxed .service-box, .services-bg-img .service-box {
        padding: 2rem 1rem;
    }
    #benefits .mega-section{
        padding-bottom: 0;
    }
    .advgb-icons{
        display: none;
    }
    #footer-widget h3.widget-title {
        margin-bottom: 0.5rem;
        margin-top: 2rem;
    }
    #footer-widget {
        padding-bottom: 4rem;
        padding-top: 2rem;
    }
    #footer-widget p{
        margin-bottom: 0;
    }
    .service-box ul{
        padding-left: 2rem;
    }
}
@media (max-width: 768px){
    .faq > div {
        padding-left: 0;
        padding-right: 0;
    }
    div.sgpb-content {
        padding: 0px 1rem !important;
    }
    
    .white-quiz-box, .dark-quiz-box {
        padding: 2rem 3rem!important;
    }
    .wp-block-buttons.is-content-justification-right>.wp-block-button {
        margin-bottom: 15px;
    }
    button.jet-form-builder__submit {
        margin-top: 0;
    }
}
@media (max-width: 320px){
    .jet-form-builder__field-wrap.checkradio-wrap span {
        font-size: 14px;
    }
    .white-quiz-box, .dark-quiz-box {
        padding: 1rem 2rem!important;
    }
}
/* form fields */
.nf-form-content{
    padding: 0;
}
.nf-form-fields-required{
    padding-bottom: 1rem;
}
.nf-form-content .list-select-wrap .nf-field-element>div, .nf-form-content input:not([type=button]), .nf-form-content textarea {
    background: var(--light-gray-text);
    border: 2px solid var(--accent);
    color: gray;
}
.listradio-wrap .nf-field-element label.nf-checked-label:before {
    background: var(--accent);
}
.listradio-wrap .nf-field-element label.nf-checked-label:after {
    border-color: var(--accent);
}
.nf-form-content button, .nf-form-content input[type=button], .nf-form-content input[type=submit]{
    background-color: var(--accent);
}
.nf-form-content button:hover, .nf-form-content input[type=button]:hover, .nf-form-content input[type=submit]:hover{
    border: 2px solid var(--accent);
    color: var(--accent);
}
.nf-field-element .ampm, .nf-field-element .minute, .nf-field-element .hour{
    border: 2px solid var(--accent);
    background-color: var(--light-gray-text);
    padding: .5rem;
    padding-right: 0;
    margin-top: .5rem;
}
.listradio-wrap .nf-field-element label:after {
    background: var(--light-gray-text);
    border: 2px solid var(--accent);
    transition: all .5s;
}
.nf-field-element .hour{
    border-right: none;
}
.sgpb-theme-1-content {
    border-bottom-width: 0px !important;
}
.sgpb-theme-1-content{
    border-radius: 0!important;
}
button.jet-form-builder__submit {
    background-color: var(--accent);
    margin-top: 15px;
}
button.jet-form-builder__submit:hover {
    background-color: transparent;
    color: var(--accent);
}
.jet-form-builder-row{
    padding: 0!important;
}
.jet-form-builder__label-text {
    font-size: 18px;
    line-height: 20px;
}
.jet-form-builder__label-text {
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 25px;
    padding: 0;
}
/* popup */
.sgpb-popup-overlay{
    z-index: 1032!important;
}
.sgpb-popup-dialog-main-div-wrapper > div{
    z-index: 1033!important;
}
.flatpickr-calendar.open {
    margin-top: -43px;
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
    background: var(--accent);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: var(--accent);
}
/*scroll*/
::-webkit-scrollbar-button {
    background-image:url('');
    background-repeat:no-repeat;
    width:5px;
    height:0px
}
::-webkit-scrollbar-track {
    background-color:var(--light-gray-text)
}
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background-color:var(--accent);
    transition: all .5s;
}
::-webkit-scrollbar-thumb:hover{
    background-color:var(--accent-light);
    transition: all .5s;
    cursor: pointer;
}
::-webkit-resizer{
    background-image:url('');
    background-repeat:no-repeat;
    width:8px;
    height:0px
}
::-webkit-scrollbar{
    width: 8px;
}
/* quiz */
.quiz .question{
    transition: all 0.5s ease-in-out 0s;
}
.quiz .swiper-button-next p, .swiper-button-prev p{
    margin-bottom: 0;
}
.quiz .swiper-button-prev, .quiz .swiper-button-next{
    width: auto;
    height: auto;
    background-color: inherit;
    position: inherit;
}
.quiz .swiper-button-prev:hover, .quiz .swiper-button-next:hover {
    background-color: inherit;
}
.quiz .swiper-button-prev {
    transform: translateX(0);
}
.quiz .wp-block-button__link {
    margin-right: 0;
    margin-bottom: 0;
}
.jet-form-builder__field-label > span::before{
    width: 1rem!important;
    height: 1rem!important;
    border: 2px solid var(--accent)!important;
    background-size: 100% 100%!important;
}
.jet-form-builder__field-label.for-checkbox :not(:disabled):not(:checked):hover + span::before, .jet-form-builder__field-label.for-radio :not(:disabled):not(:checked):hover + span::before {
    border-color: var(--accent-light)!important;
}
.jet-form-builder__field-label.for-checkbox :not(:disabled):active + span::before, .jet-form-builder__field-label.for-radio :not(:disabled):active + span::before {
    background-color: var(--accent-light)!important;
    border-color:var(--accent-light)!important;
}
.jet-form-builder__field-label.for-checkbox :checked + span::before, .jet-form-builder__field-label.for-radio :checked + span::before, 
.jet-form-builder__field-label.for-radio :checked + span::before, .jet-form-builder__field-label.for-radio :checked + span::before {
    border-color: var(--accent)!important;
    background-color: var(--accent)!important;
}
.jet-form-builder-message--success {
    border: 1px solid #9c4f22!important;
    color: #9c4f22!important;
    width: fit-content!important;
    margin: 30px auto!important;
}
.quiz .question-1 label {
    margin-bottom: 2rem;
    margin-top: .5rem;
}
.quiz .wp-block-buttons{
    margin-top: 15px;
}
.dark-quiz-box{
    background-color: var(--shark);
    color: white;
    padding: 2rem;
    padding-left: 3rem;
}
.dark-quiz-box h2{
    color: white;
}
.dark-quiz-box p{
    margin-bottom: 0;
}
.white-quiz-box{
    background: white;
    padding: 2rem;
    padding-right: 3rem;
}

.white-quiz-box h3{
    margin-bottom: 2rem;
}
.white-quiz-box .wp-block-button{
    margin-bottom: 0;
}
input[type=number], input[type=number]:hover, input[type=number]:focus, input[type=number]:active, input[type=number]:focus-visible{
    padding: 12px;
    margin-bottom: 1rem!important;
    border-color: var(--accent)!important;
    border-style: solid;
    border-radius: 0;
}
input:not([type="button"]), textarea {
    background: var(--light-gray-text);
    border: 2px solid var(--accent);
    color: gray;
    border-radius: 0;
    box-shadow: none;
    color: #787878;
    transition: all .5s;
    font-size: 16px;
    margin: 0;
    padding: .625rem!important;;
}
textarea {
    height: 100%;
}
input:not([type=button]):focus, textarea:focus {
    background: #fff;
    border-color: #919191;
    color: #919191;
    outline: none;
    transition: all .5s;
}
select:not([multiple]) {
    height: 50px;
}
.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
  }
.quiz-table p{
    margin-bottom: .5rem;
}
.price-div{
    text-transform: none;
}
.thin-label .jet-form-builder__label-text{
    font-weight: normal;
    margin-top: 0;
    font-size: 17px;
}
.quiz ul{
    padding-left: 18px;
}
.cursor-pointer{
    cursor: pointer;
}
body:not(.home) .page-header .header-stand-out a.nav-link{
    color: var(--shark)!important;
}
body:not(.home) .page-header{
    background-color: #fff;
    color: var(--shark)!important;
    box-shadow: 0px 1px 30px 0px rgb(37 37 37 / 25%);

}
body:not(.home) .page-header .callButton a{
    color: var(--shark)!important;
    border-color: var(--shark)!important;
}
body:not(.home) .page-header .callButton a:hover{
    color: white!important;
    border-color: var(--accent)!important;
}
body:not(.home) .page-header a.nav-link {
    color:var(--shark)!important;
}
body:not(.home) .page-header a.nav-link:hover {
    color:var(--shark)!important;
}
body:not(.home) .site-content{
    margin-top: 80px;
}
body:not(.home) div#main .entry-header {
    display: block;
}
div#main .entry-header .entry-meta{
    display: none;
}
.label-right .nf-field-description {
    margin-right: 0!important;
}
header.entry-header{
    padding: 2rem;
    text-align: center;
    overflow-wrap: anywhere;
}
header.entry-header .entry-title a{
    color: white;
}
header.entry-header-archive{
    margin-bottom: 1rem;
}
article.status-publish{
    margin-bottom: 3rem;
}
.fa-comments {
    font-weight: 900;
    color:  var(--accent);
}
h1, h2, h3, h4, h5, h6{
    text-transform: inherit!important;
}
@media (max-height: 700px){
    .page-hero .hero-text-area .hero-title, .hero-swiper-slider .slide-title {
        font-weight: 600;
    }
}
.quiz .quiz-button-next .btn-primary, .wp-block-advgb-button a, .wp-block-button__link, input[type="button"] {
    color: white!important;
}
.quiz .quiz-button-prev .btn-primary, .is-style-outline a.wp-block-button__link {
    color: var(--accent) !important;
    border-color: var(--accent) !important;
}

#about i {
    color: var(--accent-light);
    font-size: 1.7rem;
    margin-bottom: 1.3rem;
    margin-right: 1rem;
}
#price i {
    color: var(--accent-light);
    font-size: 2.2rem;
    margin: auto;
    text-align: center;
    display: block;
    margin-bottom: 3rem;
}
#benefits i {
    color: var(--accent-light);
    font-size: 2.5rem;
    margin: auto;
    text-align: center;
    display: block;
    margin-bottom: 1rem;
}
.testimonials i.fa-star{
    color: var(--accent-light);
    font-size: 16px;
    margin-right: 7px;
    margin-bottom: 1rem;

}
.contact-box-icon{
    margin-left: 3rem;
    margin-bottom: 3rem;
}
.contact-icon{
    color: var(--accent-light);
    display: block;
    font-size: 30px;
    position: relative;
    top: 27px;
}
.testmonial-box, .testmonial-box > div, .testmonial-box > div > div {
    height: 100%;
}
.page-header .navbar-menu-wraper::after{
    display: none!important;
}

/*  10- Start faq Section   */
.faq {
    /*Start FAQ-Accordion Rules*/
    /*End FAQ-Accordion Rules*/
  }
  
  .faq .faq-img {
    text-align: center;
  }
  
  @media (max-width: 991px) {
    .faq .faq-img {
      margin-bottom: 3rem;
    }
  }
  
  .faq .faq-accordion .card {
    border: none;
  }
  
  .faq .faq-accordion .card:last-of-type {
    margin-bottom: 0 !important;
  }
  
  .faq .faq-accordion .card-header {
    position: relative;
    background: var(--blue-chill)!important;
    border-bottom: 2px solid var(--blue-chill)!important;
  }
  
  .faq .faq-accordion .card-header .faq-btn {
    width: 100%;
    padding-right: 30px;
    padding-left: 0;
    text-align: left;
    text-decoration: none;
    border-radius: 0;
    font-size: 1.1rem;
    font-weight: 600;
    white-space: normal;
    color: #fff;
    outline: none;
    box-shadow: none;
  }
  
  .faq .faq-accordion .card-header .faq-btn::before {
    content: "\f068";
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome 5 free' !important;
    font-weight: 900;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    left: auto;
    color: #fff;
  }
  
  .faq .faq-accordion .card-header .faq-btn.collapsed::before {
    content: "\f067";
  }
  
  .faq .faq-accordion .card-body {
    padding-top: .5rem;
  }
  
  .faq .faq-accordion .card-body .faq-answer {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.75;
    color: #5f5f5f;
  }
  
  /*  10- End faq Section  */

.bg-gray{
 background-color: var(--light-gray);
}
h2 + h2 {    
    margin-top: -1rem;
}
/* footer icons */
#footer-widget li.menu-item i{
    background-color: var(--accent);
    color: #fff;
    font-size: 14px!important;
    padding: 10px;
    text-align: center;
    width: 34px;
    margin-right: 5px;
}

.nf-form-content button, .nf-form-content input[type="button"], .nf-form-content input[type="submit"] {
    border: 2px solid var(--accent)!important;
}
.nf-field-element input[type="button"]:hover {
    color: var(--accent)!important;
}
.border-accent {
    border : 1px solid rgba(156,79,34,.5);
}